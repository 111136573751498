<template>
  <div class="top-Menu">
    <div class="top">
      <img class="logo" src="img/cbj_icon.png" :alt="$t('splash_alt')">
      <span v-html="nameGuia"></span>
      <div v-on:click="toSettings()" v-if="showSettings && onboardingExist" class="tag toSettings" :id="'tagSettings'" :ref="'tagSettings'" :load="loadSVG('tagSettings', 'btn_edit.svg', colors.color_principal)" :aria-label="$t('tosettings')"></div>
      <div v-on:click="getFullList2()" v-if="showList2" class="toList2 tag" :id="'tagList'" :ref="'tagList'" :load="loadSVG('tagList', 'b_list.svg', colors.color_principal)" :aria-label="$t('tolist')"></div>
    </div>
    <div class="bottom" v-if="showSearch">
      <div v-on:click="toHome()" v-if="showBtnHome" class="toHome tag" :id="'tagHome'" :ref="'tagHome'" :load="loadSVG('tagHome', 'btn_home.svg', colors.color_principal)" :aria-label="$t('tohome')"></div>
      <input type="text" v-model="search" placeholder="Search..." name="search" id="search" :aria-label="$t('search')" />
      <div v-on:click="getFullList()" v-if="showList" class="tag toList" :id="'tagList'" :ref="'tagList'" :load="loadSVG('tagList', 'b_list.svg', colors.color_principal)"></div>
      <div v-if="search != ''" class="resultsCont" id="resultsCont">
        <ul id="results">
          <li v-for="object in filteredObjects" :key="object.unidad_id" v-on:click="seleccionado(object.unidad_id)" :aria-label="object.nombre[lang] ">
            <span class="optMenu">{{ object.nombre[lang] }}</span>
          </li>
          <li v-if="!filteredObjects.length && search.length >= 3" :aria-label="$t('resultsnotfound')">{{ $t("resultsnotfound") }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { Analytic } from "../../public/js/analytics/Analytic.js";
var options = {
  url: "./setAnalytic.php",
  idPag: 1,
  namePag: "TopMenu",
};
var analytic = new Analytic(options);

export default {
  name: "home",
  components: {
  
  },
  props: {
    showBtnHome: {
        type: Boolean,
        default: true,
    },
    showSearch: {
        type: Boolean,
        default: true,
    },
    showList: {
      type: Boolean,
      default: true,
    },
    showSettings: {
      type: Boolean,
      default: true,
    },
    showList2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataX: this.$store.state.guiaData,
      search: "",
      lang: this.$store.state.locale,
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {

  },
  computed: {
    filteredObjects() {
      var locale = this.$store.state.locale;
      var data = this.$store.state.guiaData;
      if (this.search == "" || this.search.length < 3) {
        return [];
      } else {
        return data.filter(p => {
          return p.nombre[locale].toLowerCase().normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "").indexOf(this.search.toLowerCase().normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")) != -1;
        });
      }
    },
    onboardingExist(){
      return this.$store.state.onboardingExist;
    },
    nameGuia(){
      return this.$store.state.nameGuia;
    },
    colors(){
      return this.$store.state.guia.colores;
    }
  },
  methods: {
    toSettings: function () {
      this.$store.commit("setShowOnBoarding", true);
      analytic.registerEvent("Settings", "open");
    },
    toHome: function () {
      this.$store.commit("setShowListView", false);
      this.$store.commit("setShowItemView", false);
      this.$store.commit("setPointData", null);
      this.$store.commit("setVisitName", null);
      this.$store.commit("setPointId", null);
      this.$store.commit("setIsShowingList", false);
      this.$store.commit("saveFilterId", null);
      console.log("to Home");
      analytic.registerEvent("ToHome", "open");
    },
    getFullList: function () {
      console.log("open");
      this.$store.commit("setShowListView", true);
      this.$store.commit("setShowItemView", false);
      analytic.registerEvent("toList", "open");
      this.$store.commit("setIsShowingList", true);
      this.$store.commit("saveCustomData", false);
      this.$store.commit("setIsListGeneral", false);

      if(this.$store.state.visitName != null){
        var lista = this.$store.state.visitUnits;
        this.$store.commit("saveActualData", lista);
        if(this.$store.state.isShowingList){
          this.$store.commit("saveActualData", this.$store.state.guiaData);
          this.$store.commit("saveFilterId", null);
        }
        if(this.$store.state.pointData != null){
          this.$store.commit("setIsShowingList", false);
        }
      }else{
        console.log("FULL");
        this.$store.commit("setPointData", null);
        var list = this.$store.state.guiaData;
        this.$store.commit("saveActualData", list);
        this.$store.commit("setIsListGeneral", true);
      }
    },
    getFullList2: function () {
      console.log("open 0");
      //analytic.registerEvent("ListView", "closed");
      this.$store.commit("setShowListView", true);
      this.$store.commit("setShowItemView", false);
      this.$store.commit("setPointData", null);
      analytic.registerEvent("toList", "open");
      this.$store.commit("saveCustomData", false);
      if(this.$store.state.visitName == null){
        this.$store.commit("setIsShowingList", true);
        var lista = this.$store.state.guiaData;
        this.$store.commit("saveActualData", lista);
      }else{
        this.$store.commit("setIsShowingList", false);
        var list = this.$store.state.visitUnits;
        this.$store.commit("saveActualData", list);
      }
    },
    seleccionado: function (numero) {
      console.log("buscando");
      analytic.registerEvent("Search", numero);
      for (var punto in this.$store.state.guiaData) {
        var localdata = this.$store.state.guiaData[punto];
        if (parseInt(numero) == localdata.unidad_id) {
          console.log(localdata.nombre[this.lang]);
          analytic.registerEvent("SearchName", localdata.nombre["es"]);
          //analytic.registerEvent("SearchId", localdata.unidad_id);
          // store data for other views
          console.log(localdata);
          this.$store.commit("setPointData", localdata);
          this.$store.commit("setVisitName", null);
          this.$store.commit("setPointId", localdata.unidad_id);
          // this.camera = 'off';
        }
      }
    },
    loadSVG(id, icono, color) {
      var path = `${this.publicPath}/img/svg/${icono}`;
      fetch(path, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin'
      }).then((response) => response.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          var elements = data.getElementsByTagName('path');
          for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i);
            if (element.hasAttribute("fill")) {
              element.setAttribute("fill", color)
            }
            if (element.hasAttribute("stroke")) {
              element.setAttribute("stroke", color)
            }
          }
          this.$refs[id].innerHTML = "";
          this.$refs[id].appendChild(data.documentElement)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
  }
};
</script>

<style lang="scss">
.top-Menu {
  margin: 1rem 0;
  width: 100%;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
    object-position: center;
  }

  .top,
  .bottom {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;

    .logo{
      left: 0;
    }

    .toHome {
      left: 0;
      margin-right: 0.5rem;
    }

    input[type="text"] {
      width: 100%;
      //margin-right: 0.5rem;
      height: 30px;
      border-radius: 10px;
      background-color: #eeeeee;
      border: none;
      padding-left: 10px;
    }

    span {
      font-family: "bold";
      font-size: 18px;
      color: var(--general-titles-color);
      width: 68%;
    }

    .toList{
      margin-left: 0.5rem;
    }

    .resultsCont {
      position: absolute;
      top: 107px;
      z-index: 1;
      left: 0;
      right: 0;
      margin: auto;
      width: 90%;
      max-height: 65%;
      background-color: var(--home-opt-border);
      //display: none;
      overflow: auto;

      ul {
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          color: var(--general-bg);
          font-weight: bold;
          margin: 5px;
          padding: 5px;
          border-bottom: 1px solid;
          text-align: left;

          span {
            color: var(--general-bg);
            font-size: 16px;
          }
        }

        :nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
  }

  .top {
    margin-bottom: 1rem;
    position: relative;
    height: 40px;

    span {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      pointer-events: none;
    }

    .tag, img {
      position: absolute;
      margin: 0 auto;
    }

    .toSettings, .toList2 {
      right: 0;
    }
  }
}
</style>